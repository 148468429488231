<template>
  <JackText />
</template>

<script>
import JackText from './components/JackText.vue'

export default {
  name: 'App',
  components: {
    JackText
  }
}
</script>

<style>
#app {

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 5px;
}
</style>
