<template>
  <div class="w-full xl:w-100 mt-10">
    <div class="flex flex-row justify-items-center justify-between my-10 mx-auto w-full lg:w-1/2">
      <h1 class="font-bold text-indigo-600 flex text-xl">Papa's tekst ideeën</h1>
      <button
        type="button"
        class="border w-75 rounded-lg font-bold bg-pink-600 text-white py-2 px-4"
        @click="enableTodo"
      >Nieuw</button>
    </div>
    <!-- add todo block -->
    <div v-show="showAddTodo">
      <div class="shadow mx-auto mt-2 p-2 w-full lg:w-1/2">
        <form class="w-full max-auto mt-5">
          <div class="md:flex md:items-center mb-6">
            <div class="w-1/2">
              <label
                class="text-gray-500 font-bold text-right mb-1 md:mb-0 pr-4"
                for="inline-full-name"
              >Tekst</label>
            </div>
            <div class="text-left">
              <textarea
                v-model="todo"
                rows="3"
                class="appearance-none border border-indigo-400 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline focus:bg-white focus:border-indigo-800"
                id="inline-todo"
              ></textarea>
              <small :class="{'textfull': charsUsed(this.todo) > maxChars, 'textokay': true}">Karakters: {{ charsUsed(this.todo) }} /  {{ maxChars }}</small>&nbsp;
              <small :class="{'textfull': charsUsedNoSpaces(this.todo) > maxChars, 'textokay': true}">(zonder spaties: {{ charsUsedNoSpaces(this.todo) }} /  {{ maxChars }})  </small>
            </div>
          </div>
          <button type="button" class="w-1/4 rounded bg-indigo-500 text-white shadow-sm py-2 mx-auto" @click="addTodo()">Toevoegen</button>
          <button type="button" class="ml-4 w-1/4 rounded bg-red-500 text-white shadow-sm py-2 mx-auto" @click="close()">Sluiten</button>
        </form>
      </div>
    </div>
    <!-- list of todos block -->
    <div
      v-for="(todo, index) in todos"
      :key="index"
      class="w-full lg:w-1/2 flex justify-between bg-indigo-100 text-indigo-700 mx-auto border my-2 p-3 rounded"
    >
    <div @dblclick="markAsComplete(todo)" class="text-lg text-left">
      <p style="white-space: pre;" :class="{'completed': todo.completed}">{{ todo.todo }}</p>
      <small :class="{'textfull': charsUsed(todo.todo) > maxChars, 'textokay': true}">Karakters: {{ charsUsed(todo.todo) }} /  {{ maxChars }}</small>&nbsp;
      <small :class="{'textfull': charsUsedNoSpaces(todo.todo) > maxChars, 'textokay': true}">(zonder spaties: {{ charsUsedNoSpaces(todo.todo) }} /  {{ maxChars }})</small>
    </div>

    <span><button @click="removeTodo(index)" class="text-pink-700 font-bold text-lg">x</button></span>
    </div> 
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const maxChars = 42;
    const showAddTodo = ref(false);
    const todo = ref("");
    const completed = ref(false);

    // default todo array †
    const defaultTodoData = [
      {
        completed: false,
        todo: "Jack Daniël Cornelis Laroo\n1943 - 2023",
      },
      {
        completed: false,
        todo: "In liefde volle herinnering\nJack Laroo",
      },
      {
        completed: false,
        todo: "De oefening van het lichaam is van weinig nut\nJack"
      },
      {
        completed: false,
        todo: "Jack Laroo\naugustus 1943 - februari 2023",
      },
      {
        completed: false,
        todo: "Jack Laroo\n* Amsterdam 1943 - † Ameide 2023",
      },
      {
        completed: true,
        todo: "Jack Laroo\n6 augustus 1943 - 15 februari 2023",
      },
    ];

    // get todo from localstroage if set else get the default one
    const todosLists = JSON.parse(localStorage.getItem("jacktext")) || defaultTodoData;
    const todos = ref(todosLists);

    // add todo
    function addTodo() {
      if (charsUsedNoSpaces(todo.value) > 0) {
        todos.value.push({
          completed: false,
          todo: todo.value.trim(),
        });
        todo.value = "";
      }
      saveTodo();
    }

    // enable add todo modal
    const enableTodo = () => {
      showAddTodo.value = !showAddTodo.value;
    }

    const close = () => {
      showAddTodo.value = !showAddTodo.value;
    }

    // remove todo
    function removeTodo(index) {
      todos.value.splice(index, 1)
      saveTodo();
    }

    // mark as complete
    function markAsComplete (todo) {
      todo.completed = !todo.completed;
      completed.value = true;
      saveTodo();
    }

    // save todo in localStorage
    function saveTodo () {
      localStorage.setItem("jacktext", JSON.stringify(todos.value));
    }

    function charsUsed (text) {
      let sanitized = text.trim()
      sanitized = sanitized.replace(/(\r\n|\n|\r)/g,"")
      return sanitized.length
    }

    function charsUsedNoSpaces (text) {
      let sanitized = text.trim()
      sanitized = sanitized.replace(/(\r\n|\n|\r|\s)/g,"")
      return sanitized.length
    }

    return { todos, showAddTodo, enableTodo, addTodo, markAsComplete, saveTodo, todo, removeTodo, completed,close, charsUsed, charsUsedNoSpaces, maxChars };
  },
};
</script>

<style>
  .completed {
     text-decoration: line-through;
  }

  .textfull {
     color: red !important;
  }

  .textokay {
     color: green;
  }
</style>

